import logo from "./logo.svg";
import Proyectgallery from "./componentes/Proyectgallery/proyectgallery.jsx";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{ height: "100px" }}>
        <h1>SITIO WEB EN CONTRUCCION</h1>
        <span> Algunos proyectos:</span>
      </header>
      <main>
        <Proyectgallery></Proyectgallery>
      </main>
    </div>
  );
}

export default App;
