import React, { useState } from "react";
import "./proyectgallery.css";
export default function Proyectgallery() {
  const [show_popup, Setshowpopup] = useState(false);
  const click_popupvideo = (url) => {
    console.log("url enviada", url);
    Setshowpopup(true);
  };
  return (
    <>
      <div className="container">
        <div className="video-container">
          <div className="video" onClick={click_popupvideo}>
            <iframe
              src="https://player.vimeo.com/video/916311502?h=e6dfc88c50"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          <div className="video">
            <iframe
              src="https://player.vimeo.com/video/916515649?h=472f940c88"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          <div className="video">
            <iframe
              src="https://player.vimeo.com/video/916512874?h=0b65a3082a"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        {show_popup && (
          <div className="popup-video">
            <span>&times;</span>
            <iframe
              src="https://player.vimeo.com/video/916311502?h=e6dfc88c50"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        )}
      </div>
    </>
  );
}
